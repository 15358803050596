.auth-section {
    min-height: 100vh;
    background-image: url('/images/bg/mosque.png');
    background-position: bottom center;
    background-size: cover;
    .auth-container {
        display: flex;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
        padding: 4rem 1rem;
        .announcment {
            background-image: url('/images/figuers/announcment.png');
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            width: 75vw;
            height: 75vh;
            min-height: 500px;
            padding: 0rem 25%; }
        .auth-tabs-container {
            display: flex;
            flex-direction: column;
            border-radius: 4px;
            overflow: hidden;
            .tabs-links-wrapper {
                display: flex;
                .tab-link {
                    display: block;
                    width: 50%;
                    background-color: $purple-400;
                    text-decoration: none !important;
                    text-align: center;
                    padding: 1rem 0.5rem;
                    color: white;
                    transition: all 300ms;
                    &:hover {
                        background-color: darken($purple-400,10%); }
                    &.active {
                        background-color: $purple-900;
                        pointer-events: none; } } }
            .tabs-body-wrapper {
                .login-page {
                    max-width: 400px; }
                .choose-register-page {
                    max-width: 400px; }
                .register-page {
                    width: 1000px;
                    max-width: 700px !important; } } } } }
@media (max-width: 850px) {
    .auth-section {
        .auth-container {
            .announcment {
                padding: 0 20%;
                width: 90vw;
                height: 9vh; } } } }

@media( max-width: 700px) {
    .auth-section {
        .auth-container {
            .auth-tabs-container {
                .tabs-body-wrapper {
                    .register-page {
                        width: auto; } } } } } }
