body {
    font-family: 'Montserrat','DroidArabicKsufi', san-serif;
    // color: $gray-900
    background-color: white;
    overflow-x: hidden;
    scroll-behavior: smooth; }

.disabled {
    opacity: 0.5;
    user-select: none;
    pointer-events: none; }
