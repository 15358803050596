.anticon {
    vertical-align: 0; }
//text colors
.text-purple-900 {
    color: $purple-900; }
.text-purple-400 {
    color: $purple-400; }



.text-purple-1000 {
    color: $purple-1000; }
.text-gold-500 {
    color: $gold-500; }
.text-gold-450 {
    color: $gold-450; }
.text-gold-400 {
    color: $gold-400; }


//link colors

.link-purple-450 {
    color: $purple-450;
    text-decoration: none !important;
    &:hover {
        color: darken($purple-450,10%); } }

.link-purple-900 {
    color: $purple-900;
    text-decoration: none !important;
    &:hover {
        color: darken($purple-900,10%); } }
.link-gold-500 {
    color: $gold-500;
    text-decoration: none !important;
    &:hover {
        color: darken($gold-500,10%); } }
.link-gold-450 {
    color: $gold-450;
    text-decoration: none !important;
    &:hover {
        color: darken($gold-450,10%); } }
.link-gold-400 {
    color: $gold-400;
    text-decoration: none !important;
    &:hover {
        color: darken($gold-400,10%); } }

.text-dots {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap; }
.user-select-none {
    user-select: none; }
//text fontweight
.weight-400 {
    font-weight: 400 !important; }
.weight-500 {
    font-weight: 500 !important; }
.weight-600 {
    font-weight: 600 !important; }
.weight-700 {
    font-weight: 700 !important; }

.font-family-en {
    font-family: 'Montserrat', sans-serif; }
.font-family-ar {
    font-family: 'DroidArabicKufi'; }

//text size
.font-Xsmall {
    font-size: 11px; }
.font-small {
    font-size: 12px; }
.font-Lsmall {
    font-size: 14px; }
.font-medium {
    font-size: 16px; }
.font-Lmedium {
    font-size: 18px; }
.font-large {
    font-size: 20px; }
.font-Xlarge {
    font-size: 30px; }
@media (min-width: 1660px) {
    .font-Xsmall {
        font-size: 14px; }
    .font-small {
        font-size: 16px; }
    .font-Lsmall {
        font-size: 18px; }
    .font-medium {
        font-size: 20px; }
    .font-Lmedium {
        font-size: 26px; }
    .font-large {
        font-size: 28px; }
    .font-Xlarge {
        font-size: 36px; } }
@media (max-width: 600px) {
    .font-Xsmall {
        font-size: 9px; }
    .font-small {
        font-size: 11px; }
    .font-Lsmall {
        font-size: 12px; }
    .font-medium {
        font-size: 14px; }
    .font-Lmedium {
        font-size: 16px; }
    .font-large {
        font-size: 18px; }
    .font-Xlarge {
        font-size: 26px; } }
@media (max-width: 420px) {
    .font-Xsmall {
        font-size: 7px; }
    .font-small {
        font-size: 9px; }
    .font-Lsmall {
        font-size: 10px; }
    .font-medium {
        font-size: 12px; }
    .font-Lmedium {
        font-size: 14px; }
    .font-large {
        font-size: 16px; }
    .font-Xlarge {
        font-size: 23px; } }
@media (max-width: 350px) {
    .font-Xsmall {
        font-size: 6px; }
    .font-small {
        font-size: 8px; }
    .font-Lsmall {
        font-size: 9px; }
    .font-medium {
        font-size: 11px; }
    .font-Lmedium {
        font-size: 13px; }
    .font-large {
        font-size: 15px; }
    .font-Xlarge {
        font-size: 20px; } }
@media (max-width: 335px) {
    .font-Xsmall {
        font-size: 5px; }
    .font-small {
        font-size: 7px; }
    .font-Lsmall {
        font-size: 8px; }
    .font-medium {
        font-size: 10px; }
    .font-Lmedium {
        font-size: 12px; }
    .font-large {
        font-size: 14px; }
    .font-Xlarge {
        font-size: 17px; } }
