
a:hover {
  text-decoration: none; }

.MuiTypography-body1 {
    color: #000000;
    font-family: 'DroidArabicKufi' !important; }
.stage-name {
    .MuiTypography-body1 {
        font-size:9px !important {} } }
.stage-number {
    font-family: Lato;
    font-size: 40px; }
.stage-number-active {
    font-family: Lato;
    font-size: 40px;
    color:#d2ba7c !important {} }
.MuiListItem-button {
    background-color: #c1b3bf !important;
    height: 70px !important;
    color: black !important;
    .active {
        background-color:#65425f {}
        color:#d2ba7c !important {} } }
.Mui-selected {
    background-color:#65425f !important {}
    span {
        color:#d2ba7c !important {} } }
.Mui-selected:hover {
    background-color: #65425f !important; }
.MuiButtonBase-root {
    border-top: 0.1px solid white !important;
    border-bottom: 0.1px solid white !important; }
.MuiDivider-root {
    height: 100vh !important;
    background-color:#c1b3bf !important {} }
@media (max-width: 990px) {
    .MuiDivider-root {
        height: 1px !important; }
    .MuiListItemText-root {
        margin-bottom: 0 !important; }
    .list-text-item {
        background: #c1b3bf !important; }
    .selected-list-text-item {
        background: #65425f !important;

        .MuiTypography-root {
            color: #d2ba7c !important; } }
    .ant-dropdown-menu-item {
        padding-left: 0;
        padding-right: 0; }
    .ant-dropdown-menu {
        background-color: gainsboro; }
    .ant-drawer-title {
        margin-left: 20px;
        display: table-caption; }
    .ant-drawer-body {
        padding: 0; } }
.MuiTypography-body1 {
    text-decoration: none !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-right: 20px !important; }

.profile-img-container {
    position: relative; }
.edit-profile-img {
    display: block;
    max-width: 214px;
    max-height: 214px;
    margin-left: auto !important;
    border-style: none;
    margin-right: auto !important;
    vertical-align: middle;
    position: relative;
    bottom: 40px;
    height: 40px;
    text-align: center;
    background-color: rgba(255,255,255,.5);
    color: white; }
@media (max-width: 958px) {
    .aside-bar-container {
        display: none; } }

.upload-section {
    min-height: 100vh;
    background-position: bottom center;
    background-size: cover;
    .upload-container {
        display: flex;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
        padding: 4rem 1rem;
        .announcment {
            background-image: url('/images/figuers/announcment.png');
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            width: 75vw;
            height: 75vh;
            min-height: 500px;
            padding: 0rem 25%; }
        .auth-tabs-container {
            display: flex;
            flex-direction: column;
            border-radius: 4px;
            overflow: hidden;
            .tabs-links-wrapper {
                display: flex;
                .tab-link {
                    display: block;
                    width: 50%;
                    background-color: $purple-400;
                    text-decoration: none !important;
                    text-align: center;
                    padding: 1rem 0.5rem;
                    color: white;
                    transition: all 300ms;
                    &:hover {
                        background-color: darken($purple-400,10%); }
                    &.active {
                        background-color: $purple-900;
                        pointer-events: none; } } }
            .tabs-body-wrapper {
                .login-page {
                    max-width: 400px; }
                .choose-register-page {
                    max-width: 400px; }
                .register-page {
                    width: 1000px;
                    max-width: 700px !important; } } } } }
@media (max-width: 850px) {
    .upload-section {
        .upload-container {
            .announcment {
                padding: 0 0;
                width: 90vw;
                height: 9vh;
                background-size: cover; } } } }

@media( max-width: 700px) {
    .upload-section {
        .upload-container {
            .auth-tabs-container {
                .tabs-body-wrapper {
                    .register-page {
                        width: auto; } } } } } }

.fit-side-bar {
    .MuiDrawer-root {
        max-width: 100% !important;
        .MuiPaper-root {
            max-width: 100% !important; } } }

.ant-dropdown {
    &:before {
        top: unset; } }
