.form-wrapper {
    .form {
        .form-input-wrapper {
            position: relative;
            margin-bottom: 1rem;
            .input-label {
                margin-bottom: 0.35rem;
                user-select: none;
                padding: 0 1rem; }
            .input-wrapper {
                .MuiFormControl-root {
                    width: 100%;
                    .MuiInputBase-root {
                        &::before,&::after {
                            display: none; }
                        .MuiInputBase-input {
                            width: 100%;
                            border-radius: 3px;
                            border: 1px solid $purple-900;
                            height: 40px;
                            font-weight: 500;
                            padding: 0px 0.75rem;
                            box-sizing: border-box;
                            transition: all 300ms;
                            font-size: 15px;
                            &:focus {
                                border: 1px solid darken( $purple-900,20%); } } } }


                .ant-calendar-picker,.ant-upload {
                    width: 100%; }
                .react-phone-number-input__row,.PhoneInput,.react-tel-input {
                    height: 100%;
                    width: 100%;
                    padding: 0 !important;
                    .flag-dropdown {
                        background-color: transparent; }
                    .selected-flag {
                        display: flex;
                        justify-content: flex-end;
                        .flag {
                            left: 17px;
                            .arrow {
                                left: -11px; } } }
                    input {
                        &::placeholder {
                            color: rgba(gray,0.4); } }
                    #phone-form-control {
                        padding-left: 48px;
                        padding-right: 0;
                        width: 100%;
                        height: 100%; } }
                .ant-input,.ant-select-selection,.ant-input-number,.ant-calendar-picker-input,.ant-time-picker-input,.react-phone-number-input,.phone_input,.PhoneInputInput,.react-tel-input {
                    width: 100%;
                    border-radius: 3px;
                    border: 1px solid $purple-900;
                    height: 40px;
                    font-weight: 500;
                    transition: all 300ms;
                    font-size: 15px;
                    &:focus {
                        border: 1px solid darken( $purple-900,20%); }
                    .ant-input-number-handler-wrap {
                        display: none; }
                    .ant-select-selection__rendered,.ant-input-number-input-wrap {
                        height: 100%;
                        .ant-select-selection__choice {
                            border-radius: 10px;
                            background-color: rgba($purple-900,0.4);
                            border: 1px solid $purple-900;
                            margin: 7px 3px; }
                        .ant-input-number-input {
                            height: 100%; }
                        .ant-select-selection-selected-value {
                            display: flex !important;
                            align-items: center;
                            height: 100%; } } }
                &.underline-white-input {
                    .ant-input,.ant-select-selection,.ant-input-number {
                        border-radius: 0px;
                        border: none;
                        border-bottom: 1px solid white;
                        color: white !important;
                        background-color: transparent;
                        box-shadow: 0px 0px 0px !important;
                        &::placeholder {
                            color: rgba(white,0.8); } } } } } } }

.form-wrapper-card {
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 30px; }
@media (max-width: 767px) {
    .form-wrapper {
        .form {
            .form-input-wrapper {
                .input-wrapper {
                    .MuiFormControl-root {
                        .MuiInputBase-root {
                           .MuiInputBase-input {
                               height: 35px;
                               font-size: 13px; } } }
                    .ant-input,.ant-select-selection,.ant-input-number,.ant-time-picker-input,.react-phone-number-input {
                        height: 35px;
                        font-size: 13px;
                        .ant-select-selection__rendered,.ant-input-number-input-wrap {
                            .ant-select-selection__choice {
                                margin: 4px 3px; } } } } } } } }

@media (max-width: 420px) {
    .form-wrapper {
        .form {
            .form-input-wrapper {
                .input-wrapper {
                    .MuiFormControl-root {
                        .MuiInputBase-root {
                            .MuiInputBase-input {
                                height: 33px;
                                font-size: 12px; } } }
                    .ant-input,.ant-select-selection,.ant-input-number,.ant-time-picker-input,.react-phone-number-input {
                        height: 33px;
                        font-size: 12px; } } } } } }
