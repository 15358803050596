@font-face {
    font-family: "DroidArabicKufi";
    src: url("/fonts/droid-arabic-kufi/ArbFONTS-Droid-Arabic-Kufi-Bold.ttf") format("truetype"),
    url("/fonts/droid-arabic-kufi/ArbFONTS-Droid-Arabic-Kufi.ttf") format("truetype"),
    url("/fonts/droid-arabic-kufi/ArbFONTS-Droid.Arabic.Kufi_.Bold_DownloadSoftware.iR_.ttf") format("truetype"),
    url("/fonts/droid-arabic-kufi/ArbFONTS-Droid.Arabic.Kufi_DownloadSoftware.iR_.ttf") format("truetype"),
    url("/fonts/droid-arabic-kufi/ArbFONTS-Droid-Arabic-Kufi.ttf") format("truetype"),
    url("/fonts/droid-arabic-kufi/ArbFONTS-Droid-Arabic-Kufi-Bold.ttf") format("truetype"),
    url("/fonts/droid-arabic-kufi/ArbFONTS-DroidKufi-Bold.ttf") format("truetype"),
    url("/fonts/droid-arabic-kufi/ArbFONTS-DroidKufi-Regular.ttf") format("truetype");
}