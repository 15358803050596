.landing_competitions_section {
    min-height: 100vh;
    background-image: url('/images/bg/mosque.png');
    background-position: bottom center;
    background-size: cover;
    .competions-container {
        padding-top: 7rem;
        padding-bottom: 3rem;
        .competitions-slider-wrapper {
            position: relative;
            direction: ltr;
            .owl-nav {
                position: absolute;
                width: 110%;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                display: flex;
                justify-content: space-between;
                pointer-events: none;
                .owl-prev,.owl-next {
                    width: 25px;
                    height: 25px;
                    background-color: white;
                    pointer-events: all;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    outline: none;
                    font-size: 25px;
                    color: $purple-900;
                    span {
                        display: flex;
                        height: 25px;
                        width: 25px;
                        justify-content: center;
                        align-items: center; } } }
            .competition-item {
                padding: 0 2rem;
                .competition-logo-wrapper {
                    .img-holder {
                        width: 90px;
                        height: 90px;
                        border-radius: 50%;
                        margin: 0 auto;
                        overflow: hidden;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover; } } } } }
        .row {
            justify-content: center; } } }
@media (max-width: 767px) {
    .landing_competitions_section {
        .competions-container {
            .competitions-slider-wrapper {
                .competition-item {
                    .competition-logo-wrapper {
                        .img-holder {
                            width: 75px;
                            height: 75px; } } } } } } }
