.competetions-white-card-section {
    .slider-container {
        .competitions-slider-wrapper {
            direction: ltr;
            .owl-stage-outer {
                padding: 5rem 0;
                .owl-item {
                    transition: all 300ms;
                    padding: 0 1rem;
                    // opacity: 0
                    backface-visibility: hidden;
                    -webkit-font-smoothing: subpixel-antialiased;
                    transform: scale(1) translateZ(0);
                    &.active {
                        opacity: 1; }
                    &.center {
                        backface-visibility: hidden;
                        -webkit-font-smoothing: subpixel-antialiased;
                        z-index: 2;
                        transform: scale(1.07) translateZ(0);
 } } }                        // &:nth-of-type(2)


            .competiotion-card {
                box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.2);
                .img-wrapper {
                    position: static;
                    margin-top: -70px;
                    .img-holder {
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin: 0 auto;
                        img {
                            width: 110%;
                            height: 110%;
                            object-fit: cover; } } }
                .awards {
                    .award-price {
                        white-space: nowrap; } }
                .competition-description {
                    height: 70px;
                    overflow: hidden; } } } } }
@media (max-width: 991px) {
    .competetions-white-card-section {
        .slider-container {
            .competitions-slider-wrapper {
                .competiotion-card {
                    .img-wrapper {
                        margin-top: -40px;
                        .img-holder {
                            width: 70px;
                            height: 70px; } } } } } } }
