.input-error-msg-wrapper {
    position: absolute;
    color: red;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    font-size: 9px;
    padding: 0.25rem 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-end; }

.srv-validation-message,.server-error {
    display: block;
    position: relative;
    margin: 0 0.25rem;
    margin-bottom: 0.5rem;
    white-space: nowrap; }
.srv-validation-message {
    margin-right: 0;
    margin-left: 0; }
@media (max-width: 991px) {
    .input-error-msg-wrapper {
        font-size: 7px; } }

@media (max-width: 767px) {
    .input-error-msg-wrapper {
        font-size: 5px; } }
